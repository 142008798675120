export type DataForm = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

export const saveMessageToDB = async (data: DataForm): Promise<void> => {
  try {
    console.log('Data to save:', JSON.stringify(data))
  } catch (error) {
    console.error(
      'Firestore failed to post message. Error from firebase:',
      error
    )
  }
}

export const sendMessageToEmail = async (data: DataForm): Promise<void> => {
  // TODO: create config for all
  try {
    console.log('Data to send via email:', JSON.stringify(data))
  } catch (error) {
    console.error('Emailjs failed to send message. Error from emailjs:', error)
  }
}
